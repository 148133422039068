// Typography
.h1 {
  @apply text-4xl font-extrabold leading-tight tracking-tighter;
}

.h2 {
  @apply text-3xl font-extrabold leading-tight tracking-tighter;
}

.h3 {
  @apply text-3xl font-bold leading-tight;
}

.h4 {
  @apply text-2xl font-bold leading-snug tracking-tight;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

// Buttons
.btn,
.btn-sm {
  @apply font-medium inline-flex items-center justify-center border rounded-lg leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply px-8 py-3;
}

.btn-sm {
  @apply px-4 py-2;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.unstyled[type="date"]::-webkit-inner-spin-button,
.unstyled[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}